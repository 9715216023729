import React from 'react';
import HeroBlock from '../../components/HeroBlock';
import Testimonials from '../../components/Testimonials';
import Layout from '../../components/Layout';
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import Seo from '../../components/Seo'
import { StyledTimeline, TimelineItem, TimelineContent } from '../../components/Timeline';
import DirectusForm from '../../components/DirectusForm';

export default function Home() {

  return (

    <Layout>
      <Seo 
        lang="fi"
        title="Liity tekoälyä hyödyntävien johtajien kerhoon"
      />


      <HeroBlock
        bgcolor="lightest" 
        headline={(
          <h1>Liity tekoälyä hyödyntävien johtajien kerhoon.</h1>
        )}
        image={(
          <div style={{textAlign:"center"}}>
            <StaticImage 
              style={{
                borderRadius:"50%",
              }}
              transformOptions={{fit: "cover", cropFocus: "centre" }}
              height={300}
              width={300}
              src="../../../content/images/cartoon-frame5.png" alt="" 
            />
          </div>
        )}
        content={(
          <>
          <p>
            Johtaja: "Miksi minun tarvitsee ottaa tekoäly haltuun?"
          </p>
          <p>
            Sokrates: "Anna kun kysyn sinulta: onko teknologinen kehitys tärkeää alallanne vai teettekö edelleen asioita samalla tavalla kuin teitte antiikin Kreikassa?"
          </p>
          <p>
            Johtaja: "Kyllä teknologia muuttaa koko ajan työn tekemisen tapoja. On tärkeää, että ei tipu kelkasta."
          </p>
          <p>
            Sokrates: "Onko tekoäly mielestäsi teknologia, joka muuttaa työn tekemisen tapoja?"
          </p>
          </>
        )}        
      />

      <HeroBlock 
        bgcolor="lightest" 
        imagealign="left"
        image={(
          <div style={{textAlign:"center"}}>
            <StaticImage 
              style={{
                borderRadius:"50%",
              }}
              transformOptions={{fit: "cover", cropFocus: "centre" }}
              height={300}
              width={300}
              src="../../../content/images/cartoon-frame7.png" alt="" 
            />
          </div>
        )}
        content={(
          <>
 

          <p>
            Johtaja: "Kyllä, mutta sitä varten meillä on IT-osasto. Meillä on jo käynnissä tekoälyhankkeita. Olen aina ajatellut että johtajan roolissa olisi syytä pysyä etäällä yksityiskohdista."
          </p>
          <p>
            Sokrates: "Vaikuttaako tekoäly työn tekemiseen vain IT-osastolla?"
          </p>
          <p>Johtaja: "Ei vaan kaikkialla, tuotannossa, HR:ssä, talouspuolella, myynnissä, markkinoinnissa, tuotekehityksessä."
          </p>
          </>
        )}        
      />

    <HeroBlock 
        bgcolor="lightest" 
        imagealign="right"
        image={(
          <div style={{textAlign:"center"}}>
            <StaticImage 
              style={{
                borderRadius:"50%",
              }}
              transformOptions={{fit: "cover", cropFocus: "centre" }}
              height={300}
              width={300}
              src="../../../content/images/cartoon-frame6.png" alt="" 
            />
          </div>
        )}
        content={(
          <>
          <p>
            Sokrates: "Entä johtamisessa?"
          </p>
          <p>
            Johtaja: "Varmasti vaikuttaa myös johtamiseen, mutta en osaa vastata tarkemmin."
          </p>
          <p>
            Sokrates: "Jos ajattelet rooliasi johtajana, niin pitäisikö sinun osata?"
          </p>
          <p>
            Johtaja: "Nyt kun muotoilet sen noin, niin varmasti pitäisi. Pitäisi varata hetki ja ottaa tekoäly haltuun johtajan näkökulmasta."
          </p>
          </>
        )}        
      />

      <HeroBlock 
        bgcolor="light" 
        headline={(
          <h1>Hetki.io on johtajan tekoälytaitojen Personal Trainer.</h1>
        )}
        content={(

          <>
            <StyledTimeline accentcolor="brand">
            <TimelineItem accentcolor="brand">
              <TimelineContent>
                <h2>Tieteeseen nojaava Reverse Mentoring -menetelmä.</h2>
                <p>Hetki.io:n avulla kokeneet johtajat saavat mentorikseen tekoälyä natiivisti käyttävän nuoremman ammattilaisen.</p>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem accentcolor="brand">
              <TimelineContent>           
                <h2>Kiireiselle johtajalle suunniteltu tapa kehittää omaa osaamista.</h2>
                <p>Hetki.io:ssa hypätään suoraan asiaan. Ei kursseja tai jargonia. Mentorointisessiot sovitetaan omaan aikataulusi mukaan.</p>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem accentcolor="brand">
              <TimelineContent>
                <h2>Ala hyödyntää tekoälyä työssäsi heti ja tehokkaamin - ilman tekniikan tutkintoa</h2>
                <p>Hetki.io:n avulla saat omaan johtajan työkalupakkiisi uusia tekoälyä hyödyntäviä työtapoja. Tee parempia päätöksiä ja käytä vähemmän aikaa rutiineihin.</p>
              </TimelineContent>
            </TimelineItem>
          </StyledTimeline>
          </>
        )}        
      />
      <HeroBlock
        bgcolor="lightest"
        imagealign="left"
        headline={(
          <>
            <h2>Haluatko kuulla lisää?</h2>
            <p>Jätä yhteystietosi, niin otamme sinuun yhteyttä heti kun tilaa vapautuu uusille jäsenille!</p>
            <p>Ai niin, ja meillä on myös mahdollisuus ilmaiseen kokeiluun ennen pidempää sitoutumista.</p>
          </>
        )}


        content={(
          <div style={{textAlign:"center"}}>
            <div style={{maxWidth:"500px",margin:"0 auto",textAlign:"left"}}>
              <DirectusForm id="waiting_list_submission_fi" />
            </div>
          </div>
        )}
        />

      <HeroBlock bgcolor="light"
        image={(
          <div style={{textAlign:"center"}}>
            <StaticImage 
              style={{
                margin:"0 auto",
                borderRadius:"50%",
                border:"3px solid #fff",
                boxShadow:"0px 0px 4px #666"
              }}
              width={240}
              height={240}
              src="../../../content/images/founder-3.png" alt="" 
            />
            <br/>
            <span style={{fontSize:"80%"}}>*Hetki.io CEO illustrated by the AI</span>
          </div>
        )}
        content={(
          <>
          <h2>A startup company from Helsinki</h2>
          <blockquote>
            <p>We founded hetki.io because we believe AI will have a huge impact to our lives in the upcoming years. We wanted to bridge the gap in AI skills among corporate decision makers and facilitate direct knowledge transfer from the younger, AI-native generation.</p>
            <cite>Teemu Takala, founder and CEO</cite>
            
          </blockquote>
          </>
        )}
      />

    </Layout>

  )

}

